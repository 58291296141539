.footer {
  background-color: var(--body-color);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.footer__container {
  padding: 2rem 0 6rem;
}

.footer__link {
  color: var(--text-color);
}

.footer__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
  margin-bottom: var(--mb-2);
  color: var(--title-color);
}
.footer__logo-img {
  background: url(../../asset/white.png);
  background-size: cover;
  width: 60px;
  height: 60px;
}

.footer__link:hover {
  color: var(--title-color-dark);
}

.footer__list {
  display: flex;
  justify-content: center;
  column-gap: 1.5rem;
  margin-bottom: var(--mb-2);
}

.footer__social {
  display: flex;
  justify-content: center;
  column-gap: 1.25rem;
}

.footer__social-icon {
  background-color: var(--title-color);
  color: var(--container-color);
  font-size: 1.125rem;
  padding: 0.4rem;
  border-radius: 0.5rem;
  display: inline-flex;
}

.footer__social-icon:hover {
  background-color: var(--title-color-dark);
}

.footer__copy {
  display: block;
  text-align: center;
  margin-top: 4.5rem;
  font-size: var(--smaller-font-size);
  color: var(--text-color);
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
  .footer__social-icon {
    padding: 0.25rem;
    border-radius: 0.25rem;
    font-size: 1rem;
  }
}
