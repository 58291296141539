.portfolio__container {
  /* grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); */
}
.portfolio__content {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: var(--container-color);
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  /* flex-direction: column; */
  column-gap: 3rem;
  align-items: center;
}

.portfolio__img {
  width: 350px;
  border-radius: 0.25rem;
  margin-bottom: var(--mb-1);
}

.portfolio__title {
  margin-bottom: var(--mb-0-75);
}
.portfolio__button {
  color: var(--title-color);
  font-size: var(--normal-font-size);
  display: inline-flex;
  align-items: center;
  column-gap: 0.25rem;
  cursor: pointer;
}

.portfolio__button-icon {
  font-size: 1rem;
  transition: 0.3s;
}

.portfolio__button:hover .portfolio__button-icon {
  transform: translateX(0.25rem);
}

.portfolio__description-title {
  margin-top: var(--mb-1);
  margin-bottom: var(--mb-0-75);
}

.portfolio__description-para {
  margin-bottom: var(--mb-1);
}

.ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.ul li {
  padding: 1rem 2rem;
  background-color: rgba(153, 153, 153, 0.2);
  color: var(--text-color);
  border-radius: 5px;
  font-size: var(--normal-font-size);
  margin-bottom: var(--mb-1-5);
  margin-right: var(--mb-1-5);
  font-weight: var(--font-semi-bold);
}
.portfolio__layout {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: var(--z-modal);
}

.portfolio__modal {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  transition: 0.3s;
  display: none;
  width: 900px;
}

.portfolio__modal-content {
  position: absolute;
  top: -74rem;
  z-index: 1111;
  background-color: var(--container-color);
  padding: 2rem 2.75rem;
  border-radius: 1.25rem;
  left: 50%;
  /* transform: translateX(-50%); */
}
.portfolio__modal-close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  font-size: 1.5rem;
  color: var(--text-color);
}
.portfolio__modal-close:hover {
  color: var(--title-color-dark);
  cursor: pointer;
}
.portfolio__modal-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  font-size: 1.5rem;
  color: var(--text-color);
  margin-bottom: var(--mb-0-25);
}

.portfolio__modal-img {
  display: none;
  margin: auto;
}
.portfolio__modal-img-mobile {
  display: none;
}

.portfolio__modal-active {
  color: var(--text-color-dark);
}

/* Active Modal */
.active-modal {
  display: block;
  margin-bottom: var(--mb-1);
}

.portfolio__active {
  color: var(--title-color-dark);
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
}

/* For medium devices */
@media screen and (max-width: 768px) {
  .portfolio__content {
    flex-direction: column;
  }

  .portfolio__button {
    margin-bottom: var(--mb-1);
  }

  .portfolio__content-overview {
    border-bottom: 1px solid var(--text-color);
    padding-bottom: 0.75rem;
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
}

/* For small devices */
@media screen and (max-width: 350px) {
}
